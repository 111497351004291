import React, { useState } from 'react';
import { Box, Drawer, List, ListItem, ListItemText, Typography, IconButton, useMediaQuery, Tooltip } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Flag from 'react-world-flags';
import ThemeToggleButton from './ThemeToggleButton';
import '../css/Navbar.css';

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const location = useLocation();
  const drawerWidth = 250;
  const { t, i18n } = useTranslation();

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleLanguageChange = (lng) => {
    i18n.changeLanguage(lng);
  };

  const currentLanguageCode = i18n.language === 'en' ? 'GB' : 'FR';

  return (

    <Box className="navbarAll"  sx={{ display: 'flex' }}>
      {/* Bouton du menu Burger */}
      {isMobile && (
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={toggleDrawer}
          sx={{ color: 'inherit', position: 'absolute', top: 16, left: 16 }}
        >
          <MenuIcon />
        </IconButton>
      )}

      {/* Drawer pour les petits écrans */}
      <Drawer
        variant={isMobile ? "temporary" : "permanent"}
        anchor="left"
        open={isMobile ? open : true}
        onClose={toggleDrawer}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            overflow: '', // Assure qu'il n'y a pas de défilement
          },
        }}
      >
        <Box sx={{ padding: 2, marginTop: 4 }}>
          <Typography className="TitleBar" variant="h4" sx={{ textAlign: 'center', marginBottom: 1, textTransform:'uppercase' }}>
            William
          </Typography>
        </Box>
        <List>
          {[
            { text: t('home'), icon: <HomeIcon />, path: '/' },
            { text: t('about'), icon: <PersonIcon/>, path: '/about' },
            { text: t('projects'), icon: < HomeRepairServiceIcon />, path: '/projects' },
            { text: t('contact'), icon: <ConnectWithoutContactIcon />, path: '/contact' },
            { text: t('news'), icon: <NewspaperIcon />, path: '/news' },
          ].map(({ text, icon, path }) => (
            <ListItem
              button
              component={Link}
              to={path}
              onClick={isMobile ? toggleDrawer : undefined}
              key={text}
              sx={{
                marginLeft: 2,
                marginTop: 1,
                backgroundColor: location.pathname === path ? 'transparent' : 'transparent',
                color: location.pathname === path ? 'green' : 'inherit',
                '&:hover': {
                  backgroundColor: 'inherit', // Aucun changement de couleur de fond au survol
                  border: 'none', // Pas de bordure ajoutée au survol
                },
              }}
            >
              {icon}
              <ListItemText primary={text} sx={{ ml: 2 }} />
            </ListItem>
          ))}
        </List>
        <Box sx={{ padding: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>   
          <Tooltip>
          <ThemeToggleButton />
            <IconButton
              sx={{
                width: '35%',
                justifyContent: 'center',
                padding: 0,
                marginLeft: 3,
                minWidth: 'auto',
                borderRadius: 0,
                backgroundColor: 'transparent',
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
              onClick={() => handleLanguageChange(i18n.language === 'en' ? 'fr' : 'en')}
            >
             
             <Flag code={currentLanguageCode} style={{ width: 40, height: 30, backgroundColor: 'transparent' }} />
            </IconButton>
          </Tooltip>
        </Box>
      </Drawer>
    </Box>
    
  );
 
};

export default Navbar;
